<template>
  <div class="col-12 " style="display: flex; justify-content: center;align-items: center;">
    <div class="card d-flex flex-column align-items-center justify-content-center p-5"
      style=" border-radius: 32px; max-width: 690px; min-width: 300px;">
      <img src="../../assets/img/success-image.png" alt="" style=" max-height: 150px;">
      <div class="text-center mt-3">
        <h1 style="font-size: clamp(1.25rem, 1.0428rem + 0.884vw, 1.75rem);color: #3C1053;"><strong>Data Anda Telah
            Berhasil Terkirim!</strong></h1>
        <p style="font-size: clamp(0.875rem, 0.8232rem + 0.221vw, 1rem);color:#878787" class="mt-3">Terima kasih atas
          kerjasama Anda. Dengan kehadiran
          informasi yang Anda
          berikan, kami
          dapat melanjutkan dengan
          langkah-langkah selanjutnya dalam proses kami. Jika ada pertanyaan lebih lanjut atau bantuan yang Anda
          butuhkan,
          jangan ragu untuk menghubungi kami kembali. Terima kasih atas kepercayaan Anda kepada kami.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'
import { Form } from 'ant-design-vue'
import apiClient from '@/services/axios'
const useForm = Form.useForm

export default {
  name: 'ThLogin',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const checked = ref(false)
    const checkedConsent = ref(false)
    const isRegister = ref(false)
    const steps = ref(2)
    // const cookies = ref(null)
    const rules = reactive({
      name: [
        {
          required: true,
          message: 'Tolong masukkan Nama!',
        },
      ],
      gender: [
        {
          required: true,
          message: 'Tolong pilih Jenis Kelamin!',
        },
      ],
      tempatLahir: [
        {
          required: true,
          message: 'Tolong masukkan Tempat Lahir!',
        },
      ],
      ttl: [
        {
          required: true,
          message: 'Tolong masukkan Tanggal Lahir!',
        },
      ],
      nik: [
        {
          required: true,
          message: 'Tolong masukkan NIK!',
        },
      ],
      idTest: [
        {
          required: true,
          message: 'Tolong masukkan Id Test!',
        },
      ],
      jenisAsuransi: [
        {
          required: true,
          message: 'Tolong pilih Jenis Asuransi!',
        },
      ],
      namaAsuransi: [
        {
          required: true,
          message: 'Tolong masukkan Nama Asuransi',
        },
      ],
      nomorAsuransi: [
        {
          required: true,
          message: 'Tolong masukkan Nomor Asuransi',
        },
      ],
      email: [
        {
          required: true,
          message: 'Tolong masukkan Email!',
        },
      ],
      telepon: [
        {
          required: true,
          message: 'Tolong masukkan Nomor Handphone!',
        },
      ],
      password: [{ required: true, message: 'Tolong masukkan Sandi!' }],
    })
    const registerForm = reactive({
      idTest: null,
      email: null,
      password: null,
      name: null,
      gender: null,
      ttl: null,
      tempatLahir: null,
      nik: null,
      nomorAsuransi: null,
      jenisAsuransi: null,
      namaAsuransi: null,
      telepon: null,
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = async values => {
      // console.log('masuk sini')
      validate(['name', 'email', 'telepon'])
        .then(() => {
          showModal()
        })
        .catch(err => {
          console.log(err)
        })

    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    onMounted(() => {
      console.log(route.path)
      if (route.path === '/register') {
        isRegister.value = true
      }
    })

    const loadingModal = ref(false)
    const visible = ref(false)
    const showModal = () => {
      visible.value = true
    }
    const closeModal = () => {
      visible.value = false
    }
    const handleSubmit = async () => {
      //   console.log(registerForm, '<<<<')
      const res = await apiClient.post('/auth/register', registerForm)
      console.log(res, '<<<<')
      if (res.data.result.id) {
        notification.success({
          message: res.data.status,
          description: 'Sukses melakukan pendaftaran',
          duration: 3,
        })
        setTimeout(() => {
          router.push('/masuk')
        }, 1000)
      }
    }
    const handleOk = () => {
      loading.value = true

      //   setTimeout(() => {
      //     loading.value = false
      //     visible.value = false
      //   }, 2000)
    }
    const handleCancel = () => {
      visible.value = false
    }
    const handleBack = () => {
      steps.value--
    }
    watch(
      [() => checkedConsent.value, () => route.path],
      ([newCheck], [oldCheck]) => {
        console.log('watch berubah')
        if (route.path === '/register') {
          isRegister.value = true
        } else {
          isRegister.value = false
        }
      },
      { deep: true },
    )
    const { resetFields, validate, validateInfos } = useForm(registerForm, rules)

    return {
      closeModal,
      loadingModal,
      visible,
      showModal,
      handleOk,
      handleCancel,
      steps,
      isRegister,
      settings,
      loading,
      rules,
      registerForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
      checked,
      checkedConsent,
      validateInfos,
      handleBack,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      handleSubmit,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(.ant-checkbox-inner) {
  border-radius: 3px !important;
}

:global(.ant-steps-item-icon) {
  border-radius: 3px !important;
}

.custom-date-picker>div>input {
  height: 40px;
}
</style>
