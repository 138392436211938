<template>
    <div class="col-12 col-md-6 " style="display: flex; justify-content: center;align-items: center;">
        <!-- LOGIN -->
        <div :class="$style.card">
            <div class="d-flex align-items-center justify-content-center">
                <img class="" src="../../assets/img/logo-biometrix2.png" style="width: 215px; height: 88px;" />
            </div>
            <div class="mb-4 mt-5 text-center">
                <span :class="$style.welcome">Welcome Back</span>
            </div>
            <a-form :model="loginForm" :rules="rules" layout="vertical" class="mb-4" @finish="handleFinish"
                @finishFailed="handleFinishFailed">
                <a-form-item name="confirmPassword">
                    <label for="exampleFormControlInput1" class="form-label font-custom" style="font-size: 15px;">New
                        Password</label>
                    <a-input type="password" v-model:value="loginForm.email" placeholder="XXXXXXX"
                        style="height: 40px; font-size: 18px; padding-left: 15px;" />
                    <label for="exampleFormControlInput1" class="form-label font-custom mt-3"
                        style="font-size: 15px;">Confirm Password</label>
                    <a-input type="password" v-model:value="loginForm.email" placeholder="XXXXXXX"
                        style="height: 40px; font-size: 18px; padding-left: 15px;" />
                </a-form-item>
                <a-button type="primary" html-type="submit" class="btn text-center w-100 mt-1" :loading="loading"
                    style="height: 50px; font-size: 18px;" disabled>
                    <strong>Reset Password</strong>
                </a-button>
                <div class="d-flex justify-content-center mt-3">
                    <router-link :to="{ name: 'register' }" class=" font-custom ml-1 d-flex justify-content-center">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.5 18L13.5725 16.9553L9.3875 12.75L18.5 12.75L18.5 11.25L9.3875 11.25L13.5725 7.07025L12.5 6L6.5 12L12.5 18Z"
                                fill="black" />
                            <path
                                d="M12.5 1.5C14.5767 1.5 16.6068 2.11581 18.3335 3.26956C20.0602 4.42332 21.406 6.0632 22.2007 7.98182C22.9954 9.90044 23.2034 12.0116 22.7982 14.0484C22.3931 16.0852 21.3931 17.9562 19.9246 19.4246C18.4562 20.8931 16.5852 21.8931 14.5484 22.2982C12.5116 22.7034 10.4004 22.4955 8.48182 21.7007C6.56319 20.906 4.92332 19.5602 3.76956 17.8335C2.61581 16.1068 1.99999 14.0767 1.99999 12C2.00317 9.2162 3.11044 6.54733 5.07888 4.57888C7.04732 2.61044 9.71619 1.50317 12.5 1.5ZM12.5 21C14.28 21 16.0201 20.4722 17.5001 19.4832C18.9802 18.4943 20.1337 17.0887 20.8149 15.4441C21.4961 13.7996 21.6743 11.99 21.3271 10.2442C20.9798 8.49835 20.1226 6.89471 18.864 5.63604C17.6053 4.37736 16.0016 3.5202 14.2558 3.17293C12.51 2.82566 10.7004 3.00389 9.05584 3.68508C7.41131 4.36627 6.0057 5.51982 5.01677 6.99986C4.02784 8.47991 3.49999 10.22 3.49999 12C3.50277 14.3861 4.45188 16.6737 6.1391 18.3609C7.82633 20.0481 10.1139 20.9972 12.5 21Z"
                                fill="black" />
                        </svg>
                        <span class="ml-2">Kembali ke Halaman Masuk</span>
                    </router-link>
                </div>
            </a-form>

        </div>
    </div>
</template>
<script>
import { computed, reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'

export default {
    name: 'ThLogin',
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const settings = computed(() => store.getters.settings)
        const loading = computed(() => store.getters['user/user'].loading)
        const checked = ref(false)
        const checkedConsent = ref(false)
        const isRegister = ref(false)
        const steps = ref(2)
        // const cookies = ref(null)
        const rules = {
            email: [
                {
                    required: true,
                    message: 'Tolong masukkan email!',
                    trigger: 'change',
                },
            ],
            password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
        }
        const loginForm = reactive({
            email: '',
            password: '',
            name: '',
            gender: '',
            birth: '',
            nik: '',
            noAsuransi: '',
            id: '',
            jenisAsuransi: 'Pilih Jenis Asuransi',
            noHandphone: '',

        })

        const changeAuthProvider = value => {
            store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
        }
        const handleFinish = async values => {
            // console.log(values)
            const data = await store.dispatch('user/LOGIN', { ...values })
            const user = data.user || data
            console.log(data, '<<<<<<DATA')
            localStorage.setItem('accessToken', user.accessToken)
            cookies.set('sessionId', user.accessToken)
            // this.$cookies.set('sessionId', token, null, '/')
            // const sessionId = this.$cookies.get('sessionId')
            // console.log(sessionId)
            store.commit('user/SET_STATE', {
                name: user.name,
                email: user.email,
                sessionId: user.accessToken,
                role: user.role,
            })
            cookies.set('user', user, null, '/')
            if (user.role === 'siteManager') {
                router.push('/eos-monitoring')
            }
            else if (user.role === 'eos') {
                router.push('/monitoring')
            }
            else if (user.role === 'receptionist') {
                router.push('/receptionist')
            }
            else if (user.role === 'helpDesk') {
                router.push('/request')
            }
            else {
                router.push('/')
            }
            notification.success({
                message: 'Selamat Datang ' + user.name,
                duration: 5,
            })
        }
        const handleFinishFailed = errors => {
            console.log(errors)
        }
        onMounted(() => {
            console.log(route.path)
            if (route.path === '/register') {
                isRegister.value = true
            }
        })


        const loadingModal = ref(false);
        const visible = ref(false);
        const showModal = () => {
            visible.value = true;
        };
        const handleOk = () => {
            loading.value = true;
            setTimeout(() => {
                loading.value = false;
                visible.value = false;
            }, 2000);
        };
        const handleCancel = () => {
            visible.value = false;
        };
        watch(
            [() => checkedConsent.value, () => route.path],
            ([newCheck], [oldCheck]) => {
                console.log('watch berubah');
                if (route.path === '/register') {
                    isRegister.value = true
                } else {
                    isRegister.value = false
                }
            },
            { deep: true },
        );
        // const { resetFields, validate, validateInfos } = useForm(dataPayload, rulesPayload)


        return {
            loadingModal,
            visible,
            showModal,
            handleOk,
            handleCancel,
            steps,
            isRegister,
            settings,
            loading,
            rules,
            loginForm,
            changeAuthProvider,
            handleFinish,
            handleFinishFailed,
            checked,
            checkedConsent,
        }
    },

    methods: {
        // pushToBanner() {
        // this.$router.push('banner')
        // },
    },
    // data: function () {
    //   return {
    //     rules: {
    //       email: [{ required: true, message: 'Tolong masukkan username!', trigger: 'change' }],
    //       password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
    //     },
    //     loginForm: {
    //       email: 'demo@visualbuilder.cloud',
    //       password: 'VisualBuilder',
    //     },
    //   }
    // },
    // computed: {
    //   ...mapState(['settings']),
    //   loading() {
    //     return this.$store.state.user.loading
    //   },
    // },
    // methods: {
    //   changeAuthProvider(value) {
    //     this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    //   },
    //   handleFinish(values) {
    //     this.$store.dispatch('user/LOGIN', { payload: values })
    //   },
    //   handleFinishFailed(errors) {
    //     console.log(errors)
    //   },
    // },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(.ant-checkbox-inner) {
    border-radius: 3px !important;
}

:global(.ant-steps-item-icon) {
    border-radius: 3px !important;
}
</style>