<template>
  <div class="row" style="height: 100vh; width: 100vw;">
    <div class="col-12" style="display: flex; justify-content: center;align-items: center;">
      <register v-if="currentRoute === '/register'" />
      <success v-if="currentRoute === '/success'" />
      <!-- <login v-if="currentRoute === '/masuk'" />
      <forgot-password v-if="currentRoute === '/forgot-password'" />
      <confirm-password v-if="currentRoute === '/confirm-password'" /> -->
    </div>
    <!-- <div
      class="col-12 col-md-6 d-flex align-items-center justify-content-center"
      style="background-color: #AEB9E9; height:676px; min-height: 100vh"
    >
      <img src="../../../assets/img/bg-biometrix.png" alt="" style="height: 510px;" />
    </div> -->
  </div>
</template>
<script>
import { computed, reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'
import register from '../register.vue'
import success from '../success.vue'
import forgotPassword from '../forgotPassword.vue'
import confirmPassword from '../confirmPassword.vue'

export default {
  name: 'ThLogin',
  components: {
    register,
    success,
    // login,
    // forgotPassword,
    // confirmPassword,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const checked = ref(false)
    const checkedConsent = ref(false)
    const currentRoute = ref('')
    const steps = ref(2)
    // const cookies = ref(null)
    const rules = {
      email: [
        {
          required: true,
          message: 'Tolong masukkan email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: null,
      password: null,
      name: null,
      gender: null,
      ttl: null,
      nik: null,
      id: null,
      nomorAsuransi: null,
      jenisAsuransi: 'Pilih Jenis Asuransi',
      namaAsuransi: 'Pilih Jenis Asuransi',
      noHandphone: null,
      tempatLahir: null,
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = async values => {
      // console.log(values)
      const data = await store.dispatch('user/LOGIN', { ...values })
      const user = data.user || data
      console.log(data, '<<<<<<DATA')
      localStorage.setItem('accessToken', user.accessToken)
      cookies.set('sessionId', user.accessToken)
      // this.$cookies.set('sessionId', token, null, '/')
      // const sessionId = this.$cookies.get('sessionId')
      // console.log(sessionId)
      store.commit('user/SET_STATE', {
        name: user.name,
        email: user.email,
        sessionId: user.accessToken,
        role: user.role,
      })
      cookies.set('user', user, null, '/')
      if (user.role === 'siteManager') {
        router.push('/eos-monitoring')
      } else if (user.role === 'eos') {
        router.push('/monitoring')
      } else if (user.role === 'receptionist') {
        router.push('/receptionist')
      } else if (user.role === 'helpDesk') {
        router.push('/request')
      } else {
        router.push('/')
      }
      notification.success({
        message: 'Selamat Datang ' + user.name,
        duration: 5,
      })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    onMounted(() => {
      currentRoute.value = route.path
    })

    const loadingModal = ref(false)
    const visible = ref(false)
    const showModal = () => {
      if (steps.value != 2) {
        steps.value += 1
      } else {
        visible.value = true
      }
    }
    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }
    const handleCancel = () => {
      visible.value = false
    }
    watch(
      [() => checkedConsent.value, () => route.path],
      ([newCheck], [oldCheck]) => {
        console.log('watch berubah')
        currentRoute.value = route.path
      },
      { deep: true },
    )
    // const { resetFields, validate, validateInfos } = useForm(dataPayload, rulesPayload)

    return {
      loadingModal,
      visible,
      showModal,
      handleOk,
      handleCancel,
      steps,
      currentRoute,
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
      checked,
      checkedConsent,
    }
  },

  methods: {
    // pushToBanner() {
    // this.$router.push('banner')
    // },
  },
  // data: function () {
  //   return {
  //     rules: {
  //       email: [{ required: true, message: 'Tolong masukkan username!', trigger: 'change' }],
  //       password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
  //     },
  //     loginForm: {
  //       email: 'demo@visualbuilder.cloud',
  //       password: 'VisualBuilder',
  //     },
  //   }
  // },
  // computed: {
  //   ...mapState(['settings']),
  //   loading() {
  //     return this.$store.state.user.loading
  //   },
  // },
  // methods: {
  //   changeAuthProvider(value) {
  //     this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
  //   },
  //   handleFinish(values) {
  //     this.$store.dispatch('user/LOGIN', { payload: values })
  //   },
  //   handleFinishFailed(errors) {
  //     console.log(errors)
  //   },
  // },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(.ant-checkbox-inner) {
  border-radius: 3px !important;
}

:global(.ant-steps-item-icon) {
  border-radius: 3px !important;
}
</style>
